import parse from "html-react-parser";
import { Text, Flex } from "@chakra-ui/react";
import Error from "../../../assets/svg/error";

export default function Index({ errorMessage }) {
  return (
    <Flex
      p="3"
      bg="white"
      opacity=".75"
      width="100vw"
      flexWrap="wrap"
      borderBottomWidth={3}
      justifyContent="center"
      borderBottomColor="maroon"
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        width={{ base: "90%", "2xl": "1350px" }}
      >
        <Error fill="maroon" />
        <Text color="maroon" ml="10" variant="descriptionText">
          {parse(errorMessage)}
        </Text>
      </Flex>
    </Flex>
  );
}
